.background-banner-container {
  position: relative;
  height: 100vh;
  width: 100% !important;
  overflow: hidden;
  display: block;
  top: 0;
}
.banner-img-container {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  transform: translateX(-50%) translateY(-50%);
  min-height: 100%;
  min-width: 100%;
  max-width: none;
  height: 100%;
  width: auto;
  opacity: 1;
  transition: opacity 1.5s;
}
.banner-img-container img {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  transform: translateX(-50%) translateY(-50%);
  min-height: 100%;
  min-width: 100%;
  max-width: none;
  height: 100%;
  width: auto;
  opacity: 0.97;
}
.header-text-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: hsl(240deg 1% 33% / 13%);
  display: grid;
  align-items: center;
  padding-right: 3rem;
  padding-left: 3rem;
}
.header-text-container div:first-child {
  margin-top: 8rem;
}

.myName {
  font-size: 50px;
  color: white;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 1rem;
}

.header-text-container p {
  font-size: 25px;
  color: white;
}
hr {
  color: white !important;
  background-color: white !important;
  height: 2px !important;
  opacity: 0.7 !important;
}

.list-inline {
  padding-left: 0;
  margin-left: -5px;
  list-style: none;
}
.list-inline li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}
.list-inline a {
  width: 40px;
  height: 40px;
  font-size: 17px;
  color: #fff;
  border-radius: 50%;
  padding: 11px;
}
.list-inline a:hover {
  background-color: #fff;
}
.list-inline svg:hover {
  color: black;
}
@media only screen and (min-width: 768px) {
  .myName {
    font-size: 70px;
  }
  hr {
    width: 50%;
  }
  .header-text-container div:first-child {
    margin-top: 0rem;
}
}
@media only screen and (min-width:992px){
  .header-text-container, .navbar, .row-container  {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }
  h2 {
    font-size: 30px !important;
  }
  .background-banner-container {
    top: -93px;
  }
}
